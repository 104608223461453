@use '/src/stylesheets/mixins';
@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';
@use 'sass:math';

.ProductCard {
    @extend %flexCenter;
    flex-flow: column;
    align-items: stretch;
    position: relative;
    height: 100%;
    width: 100%;
    //overflow: hidden;
    justify-content: flex-start;
    // filter: drop-shadow(0px 0px functions.responsivePercentageOfReference(0.07) rgba(0, 0, 0, 0.25));
    background: white;
    border-radius: var(--tiles-radius) var(--tiles-radius) 0 0;
    overflow: hidden;
    will-change: filter;

    &__brand {
        font-weight: bold;
    }

    &__outsideGrid {
        // filter: none;
        border: 1px solid #dcdde1;
    }

    &__imageContainer {
        position: relative;
        width: 100%;
        background: #f5f5f5;
        overflow: hidden;
    }

    &__image {
        @extend %backgroundCover;
        width: 100%;
        overflow: hidden;
        background-clip: border-box;
        mix-blend-mode: multiply;
        //border-radius: var(--tiles-radius) 0 0;

        &__iconspot {
            position: absolute;
            top: functions.responsivePercentageOfReference(0.05);
            right: functions.responsivePercentageOfReference(0.05);
            width: functions.responsivePercentageOfReference(0.6);
            height: functions.responsivePercentageOfReference(0.6);
        }
    }

    &__basketButton {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        border: functions.responsivePercentageOfReference(0.025) solid #20a000;
        border-radius: 100%;
        pointer-events: all;
        z-index: 10000;
        position: static;
        display: block;

        &:active {
            transform: scale3d(0.85, 0.85, 1);
        }

        &:hover {
            background-color: #20a000;

            & > svg {
                filter: brightness(0) invert(1);
            }
        }

        & > svg {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 50%;
        }
    }

    &__banner {
        border-top: 1px rgba(0, 0, 0, 0.15) solid;
        background-color: rgba(240, 240, 240, 0.7);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        @include font.SubheadingFont();
        @include font.Small();
        padding: common.spacing('semiSmall');
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include font.BodyFont();
        padding: functions.responsivePercentageOfReference(0.15) 0 functions.responsivePercentageOfReference(0.1) 0;
        min-height: functions.responsivePercentageOfReference(0.5);
        width: 100%;

        &__outsideGrid {
            padding: functions.responsivePercentageOfReference(0.1);
        }
    }

    &__content2 {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%);
        min-width: 100%;
        border-radius: 0 0 var(--tiles-radius) var(--tiles-radius);
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        @include font.BodyFont();
        padding: functions.responsivePercentageOfReference(0.1);
        //functions.responsivePercentageOfReference(0.05);
        min-height: functions.responsivePercentageOfReference(0.66);
    }

    //gap, 50, 30
    //fade at 60%

    &__text {
        width: 100%;
        height: 100%;
        text-align: left;
        color: var(--main-text-color);
        //margin: 0.5rem 0 0.5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        @include mixins.line-clamp($lines-to-show: 1);
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }

    &__price {
        @include mixins.line-clamp($lines-to-show: 1);
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        @include font.BodyFont();
        font-weight: var(--subheadings-font-weight);
    }

    &:hover {
        filter: brightness(105%);
        position: relative;
    }

    &:active {
        transform: scale3d(0.95, 0.95, 1);
    }

    &__saleContainer {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: functions.responsivePercentageOfReference(0.1);
    }

    &__originalPrice {
        text-decoration: line-through;
        color: var(--sale-original-color);
    }

    &__newPrice {
        color: var(--sale-new-color);
    }
}
