@use '/src/stylesheets/functions';
@use '/src/stylesheets/font';

.AttractLoop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 1000;

    &__allowClickThrough {
        pointer-events: none;
    }

    &__visible {
        opacity: 1;
    }

    &__hidden {
        opacity: 0;
        pointer-events: none;
    }

    &__Top {
        width: 100%;
        height: min-content;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: end;
        flex-direction: column;
        gap: functions.responsivePercentageOfReference(0.15);
        z-index: 20;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &__Visible {
            opacity: 1;
        }
        &__HomeButton {
            margin-top: functions.responsivePercentageOfReference(0.3);
            margin-right: functions.responsivePercentageOfReference(0.3);
            stroke: var(--primary-color) !important;
            min-width: functions.responsivePercentageOfReference(0.94);
            min-height: functions.responsivePercentageOfReference(0.94);
            max-width: functions.responsivePercentageOfReference(0.94);
            max-height: functions.responsivePercentageOfReference(0.94);

            & > svg {
                stroke: var(--primary-color) !important;
            }
        }
    }
}

.AttractLoopRipple {
    position: relative;
    top: 0;
    left: 0;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: block;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    // transform: translateX(-50%);

    & > div {
        top: 0 !important;
    }

    & > div > div {
        background-color: rgba(255, 255, 255, 0.25) !important;
        animation: touch-ripple-pulse-attract-loop 2.5s infinite;
    }
}

@keyframes touch-ripple-pulse-attract-loop {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
        background-color: rgba(255, 255, 255, 1) !important;
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.5) !important;
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.AttractLoopScreen {
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &__In {
        // nothing
        z-index: 1;
    }

    &__Out {
        z-index: 10;
    }

    &__NewButtonStyle {
        outline-style: solid !important;
        outline-width: functions.responsivePercentageOfReference(0.1) !important;
        outline-color: rgba(255, 255, 255, 0.38) !important;
        background: white !important;
    }

    &__NewButtonStyleNoBackground {
        outline-style: solid !important;
        outline-width: functions.responsivePercentageOfReference(0.1) !important;
        outline-color: rgba(255, 255, 255, 0.38) !important;
    }

    &__Content {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__BackgroundImage {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        /* opacity should be 0 at the start */
        opacity: 0;

        // transform: translateX(-200vw);
        // @media (orientation: landscape) {
        //     transform: translateY(-200vw);
        // }

        &__Image {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            object-fit: contain;
        }

        &__Blur {
            filter: blur(8px);
            -webkit-filter: blur(8px);
            height: 110%;
            width: 110%;
            position: absolute;
            top: 0;
            left: 0;
            margin: -5%;
            object-fit: cover;
        }
    }

    &__TextArea {
        // position: absolute;
        // top: 25%;
        // left: 10%;
        width: 80%;
        height: 25%;

        /* opacity should be 0 at the start */
        opacity: 0;
        text-align: center;
        height: min-content;
        padding: functions.responsivePercentageOfReference(0.25);

        @include font.HeadingFont();
        color: var(--attract-loop-font-color);
        background: var(--attract-loop-background-color);

        &__Small {
            font-size: functions.responsivePercentageOfReference(0.75);
            font-weight: bolder;
        }

        &__Regular {
            font-size: functions.responsivePercentageOfReference(1);
            font-weight: bolder;
        }

        &__Large {
            font-size: functions.responsivePercentageOfReference(1.25);
            font-weight: bolder;
        }
    }

    &__ButtonArea {
        position: absolute;
        bottom: 10%;
        // left: 50%;
        // transform: translateX(-50%);

        /* opacity should be 0 at the start */
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__Button {
            border: 0;
            display: inline-block;
            padding: functions.responsivePercentageOfReference(0.15) functions.responsivePercentageOfReference(1);
            border-radius: var(--input-radius);
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            position: relative;

            @include font.SubheadingFont();
            color: var(--primary-contrast-color);
            background: var(--primary-color);
            font-size: functions.responsivePercentageOfReference(0.5);

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: #fff;
                background-color: #c1c1c1e6;
                border: 1px solid #ffffffe6;
                border-radius: var(--input-radius);
                animation: pulse-ring-2 4s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }

            &__Round {
                width: 7vw;
                height: 7vw;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.25);
                padding: 10px;
            }
        }
    }

    &__AnimationOpacityFix {
        // opacity: 0 !important;
        // visibility: hidden;
        // transform: translate(-200vw, -200vh) !important;
    }
}

@keyframes buttonPulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
