@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$expandRelativeSize: common.$contentSizeRelative;

.ProductImages {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    user-select: none;
    transition: all 0.5s ease-in-out;

    display: flex;
    flex-direction: column;

    &__expanded {
        background: black;
    }

    &__carousel {
        flex: 1;
        // height: 100%;
        // width: 100%;

        // > div {
        //     height: 100%;
        //     width: 100%;

        //     > ul {
        //         pointer-events: none;
        //     }

        //     > div {
        //         > ul {
        //             height: 100%;
        //             @include responsive.landscape {
        //                 height: 100%;
        //             }
        //         }
        //     }
        // }

        // &__expanded {
        //     > div {
        //         height: 100%;
        //         width: 100%;

        //         > div {
        //             > ul {
        //                 height: 100vh;
        //                 @include responsive.landscape {
        //                     height: 100vh;
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    &__thumbnails {
        display: flex;
        flex-direction: row;
        height: functions.responsivePercentageOfReference(1.5);
        gap: functions.responsivePercentageOfReference(0.15);

        &__thumbnail {
            width: functions.responsivePercentageOfReference(1);
            height: functions.responsivePercentageOfReference(1);
            border: 10px solid black;
        }
    }

    // &__images {
    //     @extend %flexCenter;
    //     height: 100%;
    //     width: 100%;
    // }

    &__image {
        position: relative;
        object-fit: contain; //scale-down
        transition: all 0.5s ease-in-out;
        width: 100%;
        height: 100%;
        pointer-events: none;
        @extend %backgroundContain;
    }

    // &__expandIcon {
    //     background-color: rgba($color: colours.$black, $alpha: 0.5);
    //     border-radius: var(--input-radius);
    //     position: absolute;
    //     z-index: 1;
    //     bottom: 1rem;
    //     right: 0;
    //     margin-right: 2rem;
    //     width: functions.responsivePercentageOfReference($expandRelativeSize);
    //     height: functions.responsivePercentageOfReference($expandRelativeSize);
    //     color: white;
    //     transition: all 0.5s ease-in-out;
    //     pointer-events: none;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     &__expanded {
    //         border: 1px solid rgba(255, 255, 255, 0.6);
    //     }

    //     &__pulse {
    //         pointer-events: none;
    //         background-color: rgba($color: colours.$grey, $alpha: 0.5);
    //         border-radius: var(--input-radius);
    //         border: 1px solid rgba($color: colours.$white, $alpha: 1);
    //         position: absolute;
    //         z-index: 1;
    //         bottom: 1rem;
    //         right: 0;
    //         margin-right: 2rem;
    //         width: functions.responsivePercentageOfReference($expandRelativeSize);
    //         height: functions.responsivePercentageOfReference($expandRelativeSize);
    //         color: white;
    //         animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    //     }

    //     > i {
    //         font-size: functions.responsivePercentageOfReference(0.25);
    //         padding: functions.responsivePercentageOfReference(0.1);
    //     }
    //     > path {
    //         fill: white !important;
    //     }

    //     &:hover {
    //         cursor: pointer;
    //     }
    // }
}

// @keyframes pulse-ring {
//     0% {
//         transform: scale(0.33);
//     }
//     80%,
//     100% {
//         transform: scale(1.5);
//         background-color: rgba($color: colours.$grey, $alpha: 0);
//         border: 1px solid rgba($color: colours.$white, $alpha: 0);
//     }
// }
