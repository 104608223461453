.ProductMetafield {
    pointer-events: none;
    text-decoration: none;
    color: inherit;

    &__AllowDefaultStyles {
        & * {
            all: revert;
        }
    }
}
