// Loader.scss
.GridLoader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;

    &__ball {
        width: 80%;
        height: 80%;
        background-color: var(--primary-color);
        border-radius: 50%;
        animation: grid-loader-ball-bounce 1s infinite ease-in-out;
    }
}

@keyframes grid-loader-ball-bounce {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Adding delays for each ball
.GridLoader__ball:nth-child(1) {
    animation-delay: 0.3s;
}
.GridLoader__ball:nth-child(2) {
    animation-delay: 0.6s;
}
.GridLoader__ball:nth-child(3) {
    animation-delay: 0.9s;
}
.GridLoader__ball:nth-child(4) {
    animation-delay: 0.1s;
}
.GridLoader__ball:nth-child(5) {
    animation-delay: 0.4s;
}
.GridLoader__ball:nth-child(6) {
    animation-delay: 0.7s;
}
.GridLoader__ball:nth-child(7) {
    animation-delay: 0.2s;
}
.GridLoader__ball:nth-child(8) {
    animation-delay: 0.5s;
}
.GridLoader__ball:nth-child(9) {
    animation-delay: 0.8s;
}
