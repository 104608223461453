@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.GlassModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(176,196,222,.25);
    backdrop-filter: blur(functions.responsivePercentageOfReference(0.05));
    border-radius: functions.responsivePercentageOfReference(0.25);
    padding: functions.responsivePercentageOfReference(0.15);
    width: functions.responsivePercentageOfReference(2.5);
    height: functions.responsivePercentageOfReference(2.5);
    z-index: 1000000;

    &--round {
        border-radius: 1000%;
    }

    &__content {
        overflow: visible;
    }
}