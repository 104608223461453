@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';

.BannerPane {
    display: flex;
    width: 100vw;
    height: 100%;
    transform: translateX(100%) translateZ(0);
    background-color: var(--primary-color);
    z-index: mixins.z-index(DisplayOnlyBannerPage);
    -webkit-transition: transform 0.5s 0s ease-in;
    -moz-transition: transform 0.5s 0s ease-in;
    -o-transition: transform 0.5s 0s ease-in;
    transition: transform 0.5s 0s ease-in;
    will-change: transform;
    flex-direction: column;

    &__wrapper {
        //Intentionally empty so that react-transition-group works
    }

    &__interactive {
        transform: translateY(100%) translateX(0) translateZ(0);

        &-enter {
            transform: translateY(100%)  translateZ(0);
        }

        &-enter-active {
            transform: translateY(0%) translateZ(0);
        }

        &-enter-done {
            transform: translateY(0%) translateZ(0);
        }

        &-exit {
            transform: translateY(0%) translateZ(0);
        }

        &-exit-active {
            transform: translateY(-100%) translateZ(0);
        }

        &-exit-done {
            transform: translateY(-100%) translateZ(0);
        }
    }

    &-enter {
        transform: translateX(100%)  translateZ(0);
    }

    &-enter-active {
        transform: translateX(0%) translateZ(0);
    }

    &-enter-done {
        transform: translateX(0%) translateZ(0);
    }

    &-exit {
        transform: translateX(0%) translateZ(0);
    }

    &-exit-active {
        transform: translateX(-100%) translateZ(0);
    }

    &-exit-done {
        transform: translateX(-100%) translateZ(0);
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        width: 100%;
        height: 100%;

        &__blur {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            //blur the image
            filter: blur(8px);
            -webkit-filter: blur(8px);
            width: 110%;
            height: 110%;
            margin:-5%;
        }
    }

    &__content {
        position: absolute;
        display: flex;
        //align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);

        &__title {
            @include font.HeadingFont();
            //@include font.Hero();
            color: white;
            text-align: center;
            overflow: visible;
            text-shadow: 0 0 50px rgba(0,0,0,0.3);
            width: 80vw;
            height: 40vh; //60
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-top:  calc(var(--vh, 1vh) * 22);

            & > div {
                height: 40vh;
                width: 100%;
            }
        }

        &__interactiveSection {
            margin-bottom: 80px;
            align-items: end;
            display: block;

            &__wrapper {
                display: block;
            }

            &__button {
                all: unset;
                cursor: pointer;
                background: white;
                @include font.SubheadingFont();
                @include font.Small();
                border-radius: 100px;
                padding: 20px;
                position: relative;

                //when pressed make it smaller
                &:active {
                    transform: scale(0.95);
                }

                &:hover {
                    filter: brightness(90%);
                }
            }

            &__pulse {
                position: relative;
                top: 30px + (20px * 2); //font line height height + padding
                @include responsive.tabletDesktop {
                    top: 44px + (20px * 2); //font height + padding
                }
                pointer-events: none;
                background-color: rgba($color: colours.$grey, $alpha: .5);
                border: 1px solid rgba($color: colours.$white, $alpha: .5);
                border-radius: 100px;
                width: 100%;
                height: 30px + (20px * 2); //font line height height + padding

                @include responsive.tabletDesktop {
                    height: 44px + (20px * 2); //font height + padding
                }
                color: white;
                animation: pulse-ring-2 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }
        }


    }
}

@keyframes pulse-ring-2 {
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        transform: scale(1.33);
        background-color: rgba($color: colours.$grey, $alpha: 0);
        border: 1px solid rgba($color: colours.$white, $alpha: 0)
    }
}

