@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

.CategoryProducts {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__ContentWrapper {
        padding: 0px functions.responsivePercentageOfReference(0.2);
        transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        margin-top: calc(functions.responsivePercentageOfReference(1) * 1.15);
        transform: translateY(100%);
        display: flex;
        flex-direction: column;

        &__FilterContainer {
            display: flex;
            flex-direction: column;
            gap: functions.responsivePercentageOfReference(0.075);
            margin-bottom: functions.responsivePercentageOfReference(0.075);

            &__Selected {
                display: flex;
                gap: 1.04vw;
                overflow-x: scroll;
                flex-wrap: wrap;

                &__Chip {
                    height: functions.responsivePercentageOfReference(0.48);

                    &__Chevron {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        aspect-ratio: 1;

                        & > * {
                            height: 70%;
                        }
                    }
                }
            }

            &__Options {
                // display: flex;
                // gap: 1.04vw;
                overflow-x: scroll;

                &__Chip {
                    height: functions.responsivePercentageOfReference(0.48);
                }
            }
        }

        &__GridContainer {
            //Nothing Yet
        }
    }
}
