@use '/src/stylesheets/responsive';

.AutoSizedStyledText {
    &__fill {
        width: 100%;
        height: 100%;
    }

    &__allowOverflow {
        overflow: visible !important;
    }

    &__clamp {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include responsive.landscape {
            -webkit-line-clamp: 3;
        }
    }

}