@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';


.GridNotCollapsable {
    animation: GridSlideOpen 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes GridSlideOpen {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
    }
}

.Grid {
    @include common.space(common.spacing('xSmall'), common.spacing('medium'), padding-top);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    overflow: visible;
    background-color: #F5F5F5;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.3);
    border-top-left-radius: common.$panelBorderRadius;
    border-top-right-radius: common.$panelBorderRadius;

    &__hideButton {
        position: relative;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: block;
        width: 100%;
    }

    &--collapsed {
        overflow: visible;

        .Grid__body {
            overflow-y: hidden;
        }
    }

    &--expandable {
        .Grid__header {
            cursor: grabbing;
        }
    }

    &__header {
        cursor: pointer;
        width: 100%;
        padding: 0;
        margin: 0;
        outline: none;
        background: none;
        border: none;
        text-align: left;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .HandDiv {
        display: block;
        background-color: red;
    }

    .HandDiv2 {
        margin-right: 0;
    }

    &__body {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        flex-grow: 1;
        flex-shrink: 1;
        z-index: 0;
        will-change: height;
        position: relative;
    }

    &__handIcon {
        @include mixins.z-index('favIcon');
        position: absolute;
        right: 0;
        top: 2.5rem;
        width: 20vw;
        height: 20vw;
        max-width: 12.5rem;
        max-height: 12.5rem;
        pointer-events: none;
        animation: pulse 1.5s ease infinite alternate;
        @keyframes pulse {
            from { transform: scale(0.6); }
            to { transform: scale(1.2); }


        }


        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__handIconLeft {
        position: absolute;
        left: 0;
    }

    &__title,
    &__itemsGrid {
        width: 100%;
        max-width: common.$maxGridWidth;
        margin: auto;
        @include mixins.z-index(grid);
    }

    &__title {
        @include common.space(0 15vw common.spacing('small') common.spacing('small'), 0 15vw common.spacing('medium') common.spacing('large'), padding);
        @include mixins.line-clamp($lines-to-show: 2);
        width: 100%;
        flex-shrink: 0;

        &__CENTER {
            @include common.space(0 common.spacing('small') common.spacing('small') common.spacing('small'), 0 common.spacing('large') common.spacing('medium') common.spacing('large'), padding);
            text-align: center;
        }

        &__RIGHT {
            right: 0;
            @include common.space(0 common.spacing('small') common.spacing('small') common.spacing('small'), 0 common.spacing('large') common.spacing('medium') common.spacing('large'), padding);
            text-align: right;
        }
    }

    .infinite-scroll-component__outerdiv {
        width: 100%;
        flex: 1;
    }

    &__infiniteScroll {
        @include mixins.z-index(infinite-scroll);
        position: relative;
        padding-bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__infiniteLoader {
        height: 100%;
    }

    &__itemsGrid {
        display: grid;
        grid-gap: 1rem;
        gap: 1rem;
        padding: 1rem;
        width:100%;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}
