@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
.CallToAction {
    @include font.HeadingFont(true);

    &--REGULAR {
        font-size: functions.responsivePercentageOfReference(0.4);
        line-height: functions.responsivePercentageOfReference(0.45);
        padding: functions.responsivePercentageOfReference(0.4);
    }

    &--LARGE {
        font-size: functions.responsivePercentageOfReference(0.6);
        line-height: functions.responsivePercentageOfReference(0.65);
        padding: functions.responsivePercentageOfReference(0.4);
    }
}
