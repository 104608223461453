@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/mixins';

$primaryColor: var(--primary-color);
$buttonRelativeSize: common.$contentSizeRelative;

.Button {
    @extend %flexCenter;
    width: auto;
    flex-wrap: nowrap;
    @include font.SubheadingFont(false);
    @include font.Small();
    padding: 0 common.spacing('small');
    border: 0;
    outline: none;
    cursor: pointer;
    // box-shadow: common.$buttonBoxShadow;
    font-weight: var(--subheadings-font-weight) !important;
    height: min-content;

    /*
    * State
    */

    &:active:not([disabled]) {
        transform: scale(0.9, 0.9);
    }

    &:disabled {
        color: colours.$grey;
        pointer-events: none;
        opacity: 0.5;
    }

    /*
    * Colours
    */

    &--primary {
        background-color: $primaryColor;
        color: var(--primary-contrast-color);
        outline: functions.responsivePercentageOfReference(0.015) solid $primaryColor;
    }

    &--secondary {
        background-color: colours.$secondary;
    }

    &--grey {
        background-color: colours.$greyLight;
    }

    &--darkgrey {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &--white {
        background-color: colours.$white;
    }

    &--greenflat {
        background-color: colours.$green;
        color: colours.$white;
        box-shadow: none;
    }

    &--blue {
        background-color: colours.$blue;
        color: colours.$white;
    }

    &--none {
        background-color: unset;
        box-shadow: unset;
    }

    /*
    * Size
    */

    &--auto {
        height: 100%;
        width: 100%;
    }

    &--small {
        @include mixins.button-size-small();
    }

    &--medium {
        @include mixins.button-size-medium();
        padding-left: calc(var(--responsive-reference-point) * 0.25);
        padding-right: calc(var(--responsive-reference-point) * 0.25);
    }

    &--large {
        @include mixins.button-size-large();
    }

    &--squaredCorners {
        border-radius: common.$squaredButtonBorderRadius;
    }

    &--roundedCorners {
        border-radius: common.$roundedButtonBorderRadius;
    }

    &--extraPadding {
        padding: 0 60px;
    }

    /*
    * Outline
    */

    &--outline--clearall {
        background-color: transparent;
        outline: functions.responsivePercentageOfReference(0.015) solid $primaryColor;
        box-shadow: none;
        color: $primaryColor;

        .Button__text {
            color: $primaryColor;
        }
    }

    &--outline--primary {
        background-color: transparent;
        outline: functions.responsivePercentageOfReference(0.015) solid $primaryColor;
        color: $primaryColor;

        .Button__text {
            color: $primaryColor;
        }
    }

    &--outline--greenflat {
        background-color: transparent;
        outline: functions.responsivePercentageOfReference(0.015) solid colours.$green;
        box-shadow: none;
        color: colours.$green;

        .Button__text {
            color: colours.$green;
        }
    }

    &--withBackground {
        background-color: $primaryColor;
    }

    & > * {
        @extend %flexCenterVertically;
    }

    &__text {
        font-weight: var(--subheadings-font-weight) !important;
    }
}
