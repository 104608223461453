/*
 * COLOURS
 */

$white: #ffffff;
$black: #000000;

$primary: $black !default;
$primaryLight: lighten($primary, 15) !default;
$primaryDark: darken($primary, 15) !default;

$secondary: #f1dac4 !default;
$secondaryLight: lighten($secondary, 15) !default;
$secondaryDark: darken($secondary, 15) !default;

$grey: #c1c1c1 !default;
$greyLight: lighten($grey, 20) !default;
$greyDark: darken($grey, 20) !default;

$greyInput: #f5f5f5;
$greyTitle: #5b5b5b;

$green: #20a000;

$blue: #0500ff;

$success: #28a745;
$warning: #fb9600;
$error: #dc3545;

$primaryFontColor: $white;

$overlayColor: rgba(
    $color: $black,
    $alpha: 0.6,
);
