@use '/src/stylesheets/mixins';

.GridItem {
    @extend %flexCenter;
    position: relative;
    cursor: pointer;
    will-change: transform, opacity;

    &--disabled {
        pointer-events: none;

        &:hover > div, &:active > div {
            box-shadow: unset;
            transform: unset;
        }
    }
}
