@use '/src/stylesheets/base';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/font';

.UpsellView {
  width: 100vw;
  height: mobile.vhCalc(82);
  background-color: white;
  display: flex;
  flex-direction: column;

  @include responsive.landscape() {
    width: 80vw;
    height: mobile.vhCalc(100);
  }

  &__ContentWrapper {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: functions.responsivePercentageOfReference(0.25);

    &__Inner {
      width: 100%;
      height: 100%;
    }
  }

  &__VariantModal {
    &__Content {
      padding: 0 functions.responsivePercentageOfReference(0.25);
    }

    &__Footer {
      padding: functions.responsivePercentageOfReference(0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__Price {
        padding: functions.responsivePercentageOfReference(0.15);
        border-radius: var(--tiles-radius);
        background: #D9D9D9;
        @include font.BodyFont();
        font-size: functions.responsivePercentageOfReference(0.25);
      }
    }
  }
}