@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';

.PurchaseHeader {
    position: relative;
    width: 100%;
    height: common.$panelHeaderHeightMobile;

    @include responsive.respond-above(md) {
        height: common.$panelHeaderHeight;
    }

    &__closeButton {
        position: absolute;
        height: common.$panelHeaderHeightMobile;
        width: common.$panelHeaderHeightMobile;

        @include responsive.respond-above(md) {
            height: common.$panelHeaderHeight;
            width: common.$panelHeaderHeight;
        }

        padding: 0;

        path {
            fill: black;
        }
    }

    &__body {
        @extend %flexCenter;
        width: 100%;
        height: 100%;
        padding: 0 common.$panelHeaderHeight;
    }

    &__title {
        @include mixins.line-clamp(2);
    }
}