@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';

.SlidingModal {
    position: absolute;
    top: 0;
    left: 0;
    //height: mobile.vhCalc(100);
    height: 100%;
    width: 100%;
    @include mixins.z-index(SlidingModal);
    background: none;
    pointer-events: none;
    transition: background-color 0.3s ease;

    &__open {
        background: colours.$overlayColor;
        pointer-events: all;
    }

    &__noBackgroundDarking {
        background: none;
        pointer-events: none;
    }

    &__rounded {
        &__top {
            border-radius: common.$panelBorderRadius common.$panelBorderRadius 0 0;
        }

        &__bottom {
            border-radius: 0 0 common.$panelBorderRadius common.$panelBorderRadius;
        }

        &__left {
            border-radius: common.$panelBorderRadius 0 0 common.$panelBorderRadius;
        }

        &__right {
            border-radius: 0 common.$panelBorderRadius common.$panelBorderRadius 0;
        }
    }

    &__transparent {
        pointer-events: none;
    }

    &__body {
        position: absolute;
        //height: 100%;

        &__fullHeight {
            height: 100%;
        }

        &__fullwidth {
            width: 100%;
        }

        &__transparentBackground {
            background: none;
        }

        &__startLocation {
            &__left {
                top: 0;
                left: -100%;
            }

            &__right {
                top: 0;
                right: -100%;
            }

            &__top {
                top: mobile.vhCalc(-150);
                left: 0;
            }

            &__bottom {
                bottom: mobile.vhCalc(-150);
                left: 0;
            }
        }

        &__endLocation {
            &__left {
                transform: translate(+100vw, 0);
            }

            &__right {
                transform: translate(-100vw, 0);
            }

            &__top {
                transform: translate(0, mobile.vhCalc(150));
            }

            &__bottom {
                transform: translate(0, mobile.vhCalc(-150));
            }
        }

        transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
        background: colours.$white;
        overflow: hidden;
        box-shadow: common.$bottomPanelBoxShadow;
        pointer-events: all;
        will-change: opacity;
    }
}

.SlidingModal__body {
    @include responsive.mobileOnly {
        width: 100% !important;
        border-radius: unset !important;
    }
}
