@use './common';
@use './responsive';
@use './font';
@use './functions';

/*  
 *  How to use:
 *  width: calculateGridWidth(3, 10rem);
 *  This is also used for other elements,
 *  so that these can align with the Grid.
 *  $subtract is used mainly to subtract margins used in the Grid
 *  $divide is used to give center point of the Grid
 */
 @function calculateGridWidth($noOfTiles, $subtract, $divide) {
    @return ((($noOfTiles * $tileWidth) + ($noOfTiles * common.spacing('medium')) - $subtract) / $divide);
}

/*  
 *  How to use:
 *  @include heading(red, 3rem) { color: red; };
 */
@mixin heading($color, $size, $weight: initial) {
    color: $color;
    font-size: $size;
    font-weight: $weight;
}

/*  
 *  How to use:
 *  @include z-index(Grid)
 */
@function z-index($key) {
    @return map-get(common.$z-index, $key);
}

@mixin z-index($key) {
    z-index: z-index($key) !important;
}


/*  
 *  How to use:
 *  @include line-clamp($lines-to-show: 2)
 */
@mixin line-clamp($lines-to-show: 2) {
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
}


$smallFontSize: 0.20;
$mediumFontSize: 0.25;
$largeFontSize: 0.30;

$smallPadding: 0.10;
$mediumPadding: 0.15;
$largePadding: 0.20;

@mixin icon-size-small() {
    width: functions.responsivePercentageOfReference($smallFontSize);
    height: functions.responsivePercentageOfReference($smallFontSize);
}

@mixin icon-size-medium() {
    width: functions.responsivePercentageOfReference($mediumFontSize);
    height: functions.responsivePercentageOfReference($mediumFontSize);
}

@mixin icon-size-large() {
    width: functions.responsivePercentageOfReference($largeFontSize);
    height: functions.responsivePercentageOfReference($largeFontSize);
}

@mixin button-size-small() {
    @include font.BodyFont(false);
    font-size: functions.responsivePercentageOfReference($smallFontSize);
    line-height: functions.responsivePercentageOfReference($smallFontSize);
    padding: functions.responsivePercentageOfReference($smallPadding);
}

@mixin button-size-medium() {
    @include font.BodyFont(false);
    font-size: functions.responsivePercentageOfReference($mediumFontSize);
    line-height: functions.responsivePercentageOfReference($mediumFontSize);
    padding: functions.responsivePercentageOfReference($mediumPadding);
}

@mixin button-size-large() {
    @include font.BodyFont(false);
    font-size: functions.responsivePercentageOfReference($largeFontSize);
    line-height: functions.responsivePercentageOfReference($largeFontSize);
    padding: functions.responsivePercentageOfReference($largePadding);
}