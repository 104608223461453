@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/mixins';

$buttonRelativeSize: common.$contentSizeRelative;

.ButtonIcon {
    &--auto {
        & > * {
            height: functions.responsivePercentageOfReference($buttonRelativeSize) !important;
        }
    }

    &--small {
        & > * {
            @include mixins.icon-size-small();
        }
    }

    &--medium {
        & > * {
            @include mixins.icon-size-medium();
        }
    }

    &--large {
        & > * {
            @include mixins.icon-size-large();
        }
    }
}
