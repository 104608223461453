@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

$paddingMobile: 1rem;
$paddingTabletDesktop: 2rem;

$horizontalPaddingMobile: common.spacing('xSmall') * 2 + common.$phoneIconWidth;
$horizontalPaddingTablet: common.spacing('small') * 2 + common.$phoneIconWidthTablet;

.PurchaseView {
    width: 100%;
    height: 100%;
    background-color: colours.$white;
    display: flex;
    flex-direction: column;

    &__body {
        @include common.space($paddingMobile 0, $paddingTabletDesktop 0, 'padding');
        position: relative;
        height: 100%;
        justify-items: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: functions.responsivePercentageOfReference(0.1);
    }

    &__totalPrice {
        color: black !important;
    }

    &__center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: functions.responsivePercentageOfReference(0.75);
        // width: min-content;
        transition: all 0.3s ease-in-out;
        //  flex: 1;
        margin: auto;

        &__noGap {
            gap: functions.responsivePercentageOfReference(0.5);
        }
    }

    &__left {
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        gap: functions.responsivePercentageOfReference(0.75);
        opacity: 1;
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;

        &__hidden {
            width: 0;
            opacity: 0;
        }
    }

    &__right {
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        gap: functions.responsivePercentageOfReference(0.75);
        transition: all 0.3s ease-in-out;
        width: 100%;
        height: 100%;
    }

    &__helpText {
        //@include common.space(0 $horizontalPaddingMobile, 0 $horizontalPaddingTablet, 'padding');
        //@include common.space($paddingMobile, $paddingTabletDesktop, 'margin-bottom');
        //display: block;
        //color: colours.$primaryLight;
        text-align: center;
        //margin-bottom: 0 !important;
    }

    &__tempText {
        //@include common.space(0 $horizontalPaddingMobile, 0 $horizontalPaddingTablet, 'padding');
        //@include common.space($paddingMobile, $paddingTabletDesktop, 'margin-bottom');
        //display: block;
        //color: colours.$primaryLight;
        text-align: center;
    }

    &__checkoutText {
        //@include font.CloudshelfFont();
        //@include font.Sizes25();
        display: block;
        color: var(--sale-original-color);
        text-align: center;
    }

    &__phone {
        top: 0;
        @include common.space(common.spacing('xLarge'), common.spacing('xxLarge'), 'margin-right');
    }

    &__paymentIcons {
        max-width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: functions.responsivePercentageOfReference(0.1);
        margin-bottom: functions.responsivePercentageOfReference(0.1);
        flex-wrap: wrap;
        gap: functions.responsivePercentageOfReference(0.1);
    }

    &__branding {
        max-width: 10rem;
        margin-top: 7%;
        filter: invert(80%) sepia(12%) saturate(17%) hue-rotate(329deg) brightness(80%) contrast(87%);

        @include responsive.tabletDesktop() {
            max-width: 50rem;
        }
    }

    &__qrOuter {
        //background:pink;
        width: functions.responsivePercentageOfReference(2);
        height: functions.responsivePercentageOfReference(2);
    }

    &__CardButton {
        width: functions.responsivePercentageOfReference(2);
        height: functions.responsivePercentageOfReference(2);
        outline: functions.responsivePercentageOfReference(0.025) solid black;
        border-radius: var(--tiles-radius);
        display: flex;

        & > * {
            width: 90%;
            height: 90%;

            margin: auto;
            display: block;
        }

        &:active {
            transform: scale(0.9);
        }
    }
    //style={{ width: 'fit-content', height: '100%', maxHeight: '300px', alignSelf: 'center' }}
}
