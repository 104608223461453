@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/mixins';

.sidePane {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 100%;
    height: 18vh;
    //border-top: #5b5b5b 1px solid;
    z-index: mixins.z-index('sideMenu');
    box-shadow: 0px -4px 27px 0px rgba(0, 0, 0, 0.2);

    @include responsive.landscape() {
        width: 16vw;
        height: 100%;
    }

    &__content {
        width: 100%;
        height: 100%;
        @include responsive.landscape() {
            width: 100%;
            height: 100%;
        }
    }
}
