@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$extraSmallFontSize: 0.15;
$smallFontSize: 0.2;
$mediumFontSize: 0.25;
$largeFontSize: 0.3;
$heroFontSize: 0.35;

$extraSmallLineHeightSize: 0.2;
$smallLineHeightSize: 0.25;
$mediumLineHeightSize: 0.3;
$largeLineHeightSize: 0.35;
$heroLineHeightSize: 0.4;

@mixin CloudshelfFont() {
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin CloudshelfFontBold() {
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 700 !important;
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin HeadingFont($withColour: true) {
    font-family: var(--headings-font);
    font-weight: var(--headings-font-weight);
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal;
    overflow: hidden;
}

@mixin SubheadingFont($withColour: true) {
    font-family: var(--subheadings-font) !important;
    font-weight: var(--subheadings-font-weight) !important;
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin BodyFont($withColour: true) {
    font-family: var(--body-font) !important;
    font-weight: var(--body-font-weight) !important;
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin ExtraSmallImportant {
    font-size: functions.responsivePercentageOfReference($extraSmallFontSize) !important;
    line-height: functions.responsivePercentageOfReference($extraSmallLineHeightSize) !important;
}

@mixin ExtraSmall {
    font-size: functions.responsivePercentageOfReference($extraSmallFontSize);
    line-height: functions.responsivePercentageOfReference($extraSmallLineHeightSize);
}

@mixin SmallImportant {
    font-size: functions.responsivePercentageOfReference($smallFontSize) !important;
    line-height: functions.responsivePercentageOfReference($smallLineHeightSize) !important;
}

@mixin Small {
    font-size: functions.responsivePercentageOfReference($smallFontSize);
    line-height: functions.responsivePercentageOfReference($smallLineHeightSize);
}

@mixin Medium {
    font-size: functions.responsivePercentageOfReference($mediumFontSize);
    line-height: functions.responsivePercentageOfReference($mediumLineHeightSize);
}

@mixin Large {
    font-size: functions.responsivePercentageOfReference($largeFontSize);
    line-height: functions.responsivePercentageOfReference($largeLineHeightSize);
}

@mixin Hero {
    font-size: functions.responsivePercentageOfReference($heroFontSize);
    line-height: functions.responsivePercentageOfReference($heroLineHeightSize);
}

.StyledText {
    display: block;
    line-height: 1.5;

    &__ContrastColor {
        color: var(--primary-contrast-color) !important;
    }

    &__Align {
        &__left {
            text-align: left;
        }

        &__center {
            text-align: center;
        }

        &__right {
            text-align: right;
        }

        &__start {
            text-align: start;
        }

        &__end {
            text-align: end;
        }
    }

    &__Cloudshelf {
        @include CloudshelfFont();
    }

    &__CloudshelfBold {
        @include CloudshelfFontBold();
    }

    &__Body {
        @include BodyFont();
    }

    &__Subheading {
        @include SubheadingFont();
    }

    &__Heading {
        @include HeadingFont();
    }

    &__ExtraSmall {
        @include ExtraSmall();
    }

    &__Small {
        @include Small();
    }

    &__Medium {
        @include Medium();
    }

    &__Large {
        @include Large();
    }

    &__Hero {
        @include Hero();
    }
}
