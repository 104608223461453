@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.ProductCardOptions {
    display: flex;
    flex-direction: row;
    height: 100%;

    &__buttonBuyNow,
    &__buttonOrderNow {
        margin: auto;
        color: colours.$white;
        background: var(--purchase-text-color);

        & > div {
            height: unset !important;
        }
    }

    &__buttonAddToBasket {
        margin: auto;
        background-color: transparent;
        outline: functions.responsivePercentageOfReference(0.015) solid var(--purchase-text-color);

        > * {
            color: var(--purchase-text-color) !important;
        }
    }

    &__spacer {
        flex: 1 2 auto;
    }
}
