@use '/src/stylesheets/responsive';

.FullPageError {
  &_white {
    color: white !important;
  }

  &_error_retailer {
    position: fixed;
    top: 40px;
  }

  &_error_title {
    display: flex;
    flex-direction: column;
    flex: 9;
    align-items: center;
    justify-content: center;
    color: white !important;
  }
}