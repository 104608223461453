@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/font';

.VariantSelectorModal {
    padding: functions.responsivePercentageOfReference(0.25);
    &__Header {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: functions.responsivePercentageOfReference(0.25);
    }

    &__Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: functions.responsivePercentageOfReference(0.1);
        margin-bottom: functions.responsivePercentageOfReference(0.1);
        gap: functions.responsivePercentageOfReference(0.2);

        &__Variants {
            width: 100%;
        }

        &__Quantity {
            width: 100%;

            &__Text {
                width: 100%;
                @include font.SubheadingFont();
                @include font.Medium();
            }

            &__Control {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    &__Footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        &__Buttons {
            display: flex;
            flex-direction: row;
            gap: functions.responsivePercentageOfReference(0.1);
        }

        &__Price {
            display: flex;
            flex-direction: row;
            gap: functions.responsivePercentageOfReference(0.1);
            justify-content: flex-start;
            align-items: flex-end;

            &__CurrentPrice {
                @include font.SubheadingFont();
                @include font.Large();
            }

            &__Sale {
                color: #db0000;
            }
            &__OriginalPrice {
                @include font.SubheadingFont();
                @include font.Small();
                color: #a4a4a4;
                text-decoration: line-through;
            }
        }
    }
}
