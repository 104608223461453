@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.ProductVariantOptions {
    display: flex;
    flex-direction: column;
    gap: functions.responsivePercentageOfReference(0.15);
    &__section {
        display: flex;
        flex-direction: column;
    }

    &__title {
        width: 100%;
        @include font.SubheadingFont();
        @include font.Medium();
    }

    &__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__option {
        margin-top: functions.responsivePercentageOfReference(0.1);
        margin-right: functions.responsivePercentageOfReference(0.1);
        //margin-top: common.spacing('small');
        //margin-right: common.spacing('small');
    }
}
