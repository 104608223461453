.BrandLogoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(140deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 25%);
    width: 100%;
    height: 100%;
    z-index: 2000;
    pointer-events: none;

    &__BrandLogo {
        width: 40%;
        aspect-ratio: 10/3;
        z-index: 95;
        pointer-events: none;
        margin: 0 2.2vw;
        margin-top: calc(var(--responsive-reference-point) * 0.3);

        & > img {
            display: block;
            object-fit: contain;
            object-position: left top;
        }

        &__White {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }

        &__small {
            width: 40%;
            aspect-ratio: 10/3;
        }

        &__regular {
            width: 50%;
            aspect-ratio: 10/3;
        }

        &__large {
            width: 60%;
            aspect-ratio: 10/3;
        }
    }
}
