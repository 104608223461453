@use '/src/stylesheets/mixins';
@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.ProductGroupCard {
    height: 100%;
    width: 100%;
    position: relative;
    perspective: 500px;
    -moz-perspective: 500px;
    -webkit-perspective: 500px;
    border-radius: common.$borderRadius;

    &:hover {
        filter: brightness(90%);
    }

    &:active {
        transform: scale3d(0.95, 0.95, 1);
    }

    &__overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: common.$borderRadius;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
        //z-index: auto; //Fix for oddness with Chrome!!!
    }

    &__branding {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: common.$borderRadius;
        display: flex;
        justify-content: center;
        align-items: center;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        z-index: auto; //Fix for oddness with Chrome!!!
        @include font.BodyFont();
        @include font.Small();
        color: white;
        text-shadow: 0 0 4px #000000;
        padding: 1rem;
        text-align: center;

        &__fontAwesome {
            font-size: 70px;
            line-height: 70px;
            margin-bottom: 2rem;
        }
    }

    &__ctaContainer {
        padding-bottom: functions.responsivePercentageOfReference(0.15);
        //@include common.space(common.spacing('small'), common.spacing('small'), padding-bottom);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__cta {
        @include font.HeadingFont();
        @include mixins.line-clamp($lines-to-show: 2);
        padding-top: 1rem;
        width: 100%;
        color: white;
        text-align: center;
        text-shadow: 0 0 4px #000000;
        z-index: 2;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        padding-left: 3%;
        padding-right: 3%;

        &__button {
            @include font.SubheadingFont();
            @include font.Small();
            @include mixins.line-clamp($lines-to-show: 1);
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            padding: 8.5px;
            background: #000000;
            border-radius: var(--input-radius);
            color: white;
            text-align: center;
        }
    }

    &__card {
        //Including these breaks shit in safari
        //background-color: white;
        //box-shadow: 0 0 15px rgba(0,0,0,0.1);
        border-radius: common.$borderRadius;
        height: 100%;
        width: 100%;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;

        transition: transform 1s ease-in;
        -webkit-transition: -webkit-transform 1s ease-in;
        -o-transition: -o-transform 1s ease-in;
        -moz-transition: -moz-transform 1s ease-in;
        will-change: transform;

        &__transition {
            &__verticalFlip {
                transform: rotateX(180deg);
                -moz-transform: rotateX(180deg);
                -webkit-transform: rotateX(180deg);
                -o-transform: rotateX(180deg);
            }

            &__horizontalFlip {
                transform: rotateY(180deg);
                -moz-transform: rotateY(180deg);
                -webkit-transform: rotateY(180deg);
                -o-transform: rotateY(180deg);
            }

            &__fadeOut {
                opacity: 0;
                -o-transition: opacity 1s ease-in-out;
                -moz-transition: opacity 1s ease-in-out;
                -webkit-transition: opacity 1s ease-in-out;
                transition: opacity 1s ease-in-out;
            }

            &__fadeIn {
                opacity: 1;
                -o-transition: opacity 1s ease-in-out;
                -moz-transition: opacity 1s ease-in-out;
                -webkit-transition: opacity 1s ease-in-out;
                transition: opacity 1s ease-in-out;
            }
        }
    }

    &__content {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: common.$borderRadius;
        width: 100%;
        height: 100%;
        margin: 0;
        display: block;
        position: absolute;
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        transform: rotateX(0deg);

        &__front {
        }

        &__back__verticalFlip {
            transform: rotateX(180deg);
            -moz-transform: rotateX(180deg);
            -webkit-transform: rotateX(180deg);
            -o-transform: rotateX(180deg);
        }

        &__back__horizontalFlip {
            transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
        }
    }
}
