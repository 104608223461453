.responsiveGrid {
    &__InfiniteScroll {
        //@include mixins.z-index(infinite-scroll);
        position: relative;
        padding-bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: unset !important;
    }

    &__container {
        display: grid;
        justify-items: stretch;
        align-items: stretch;
        justify-content: center;
        align-content: start;
    }

    &__item {
        width: 100%;

        grid-row: span 1;
        grid-column: span 1;

        &__hero {
            grid-row: span 2;
            grid-column: span 2;
        }

        &__tall {
            grid-row: span 2;
            grid-column: span 1;
        }

        &__wide {
            grid-row: span 1;
            grid-column: span 2;
        }
    }
}