@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.CompatibilityStyles {
    &_advancedCompatibilityScreen {
        background: white;
        border: 1px solid #ccc;
        position: fixed;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        padding: 1rem;
    }

    &_closeButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        color: #eee;
        border: 1px #1864ab solid;
        background-color: #1864ab;

        @include font.CloudshelfFontBold();
    }

    &_compatibilityScreenIcon {
        padding: functions.responsivePercentageOfReference(0.1);
        width: functions.responsivePercentageOfReference(1);
        height: functions.responsivePercentageOfReference(1);
        //overflow: visible;

    }

    &_compatibilityScreenDisplayModeButton {
        padding: 10px 10px;
        color: #eee;
        border: 1px #1864ab solid;
        background-color: #1864ab;
        z-index: 100000;

        @include font.CloudshelfFontBold();
    }

    &_testResult {
        &_pending {
            color: white;
        }

        &_checking {
            color: white;
        }


        &_success {
            color: green;
        }

        &_failure {
            color: red;
        }
    }
}