@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.ProductPrice {
    &__price {
        @include font.HeadingFont();
        @include font.Medium();
        // margin: auto;
        display: flex;
        flex-direction: row;
        gap: functions.responsivePercentageOfReference(0.075);
        font-weight: calc(var(--headings-font-weight) + 300);

        background: rgba(255, 255, 255, 0.55);
        padding: functions.responsivePercentageOfReference(0.075);
        align-items: flex-end;
    }

    &__originalPrice {
        text-decoration: line-through !important;
        color: #000000 !important;
        @include font.Small();
        @include font.SubheadingFont();
    }

    &__newPrice {
        color: var(--sale-new-color) !important;
    }
}
