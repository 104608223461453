@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$imageSizeMobile: 6.5rem;
$imageSizeTabletDesktop: 13rem;

.BasketListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: functions.responsivePercentageOfReference(0.15);
    background: white;
    border-radius: var(--tiles-radius);
    justify-content: space-between;
    filter: drop-shadow(0px 0px functions.responsivePercentageOfReference(0.07) rgba(0, 0, 0, 0.25));

    @include common.space(common.spacing('small'), common.spacing('medium'), 'margin-bottom');

    min-height: functions.responsivePercentageOfReference(2);

    //@include common.space($imageSizeMobile, $imageSizeTabletDesktop, 'min-height');

    &__imageWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: functions.responsivePercentageOfReference(0.15);
    }

    &__editButton {
        all: unset;
        cursor: pointer;
        @include font.BodyFont();
        @include font.Small();
        text-decoration: underline;
        color: blue;
        // font-weight: var(--body-font-weight) !important;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__image {
        display: flex;
        //margin-right: common.spacing('large');
        width: functions.responsivePercentageOfReference(2);
        height: functions.responsivePercentageOfReference(2);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
        filter: drop-shadow(0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25));
        background-color: white;
        border-radius: var(--tiles-radius);

        @include responsive.landscape() {
            //@include common.space(common.spacing('small'), common.spacing('medium'), 'margin-right');
        }
        @include responsive.tabletDesktop() {
            width: functions.responsivePercentageOfReference(2);
            height: functions.responsivePercentageOfReference(2);
        }

        &__quantity {
            background-color: #646464;
            border: #646464 1px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            height: functions.responsivePercentageOfReference(0.4) !important;
            aspect-ratio: 1;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            left: 5px;
            @include font.BodyFont();
            font-size: functions.responsivePercentageOfReference(0.2);
            line-height: functions.responsivePercentageOfReference(0.2);
            color: white;
            z-index: 0;

            & > div {
                height: unset !important;
            }
        }

        &__img {
            //image contain
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            //width: $imageSizeMobile;
            //height: $imageSizeMobile;
            border-radius: var(--tiles-radius);
            //@include responsive.tabletDesktop() {
            //  width: $imageSizeTabletDesktop;
            //  height: $imageSizeTabletDesktop;
            //}
        }
    }

    &__name {
        @include font.SubheadingFont();
        @include font.Small();
        font-weight: var(--subheadings-font-weight) !important;
    }

    &__optionsListItem {
        @include font.BodyFont();
        @include font.Small();
        font-weight: var(--body-font-weight) !important;
    }

    &__quantity {
        justify-content: flex-start;
    }

    &__details {
        //flex-grow: 1;
        max-width: 50vw;
        min-width: 50vw;
        height: 100%;
        padding: functions.responsivePercentageOfReference(0.15);
    }

    &__end {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        justify-content: center;
        align-items: flex-end;
        gap: functions.responsivePercentageOfReference(0.15);
    }

    &__price {
        @include font.SubheadingFont();
        @include font.Small();
        font-weight: var(--subheadings-font-weight) !important;
        flex-shrink: 0;
        padding: functions.responsivePercentageOfReference(0.15);
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: functions.responsivePercentageOfReference(0.05);

        &__original {
            @include font.SubheadingFont();
            @include font.ExtraSmall();
            font-weight: var(--subheadings-font-weight) !important;
            flex-shrink: 0;
            text-decoration: line-through;
            color: var(--sale-original-color);
        }

        &__sale {
            @include font.SubheadingFont();
            @include font.Small();
            font-weight: var(--subheadings-font-weight) !important;
            flex-shrink: 0;
            color: var(--sale-new-color);
        }
    }

    &__homeDelivery {
        width: functions.responsivePercentageOfReference(0.5);
        height: functions.responsivePercentageOfReference(0.5);
        background-color: var(--purchase-text-color);
        border-radius: 100%;
        position: relative;
        //bottom: functions.responsivePercentageOfReference(0.10);
        //right: functions.responsivePercentageOfReference(0.10);

        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
