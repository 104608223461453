@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';

.PageWithBackground {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;

    &__content {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.90) 100%);
    }

    &__overlay {
        position: inherit;
        width: 100%;
        height: 25%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);;
    }

    &--gradient-dark,
    &--gradient-light {
        .PageWithBackground__container::after {
            content: "";
            position: absolute;
            margin-top: 100vh;
            top: 0;
            height: calc(100% - 100vh);
            width: 100%;
            @include mixins.z-index(grid-gradient);
        }
    }

    &--gradient-dark {
        .PageWithBackground__container::after {
            @extend %backgroundGradientDark;
        }
    }

    &--gradient-light {
        .PageWithBackground__container::after {
            @extend %backgroundGradientLight;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 6rem;
        color: white;
        text-align: center;
        text-shadow: 0 4px 10px #000000;
        flex-shrink: 0;
        flex-grow: 0;

        @include responsive.tabletDesktop {
            height: 12rem;
        }
    }

    &__noFrame {
        position: fixed;
        width: 100%;
        height: 65%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    }
}
