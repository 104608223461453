@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/functions';

.TextInput {
    width: 100%;
    position: relative;

    &__input {
        outline: functions.responsivePercentageOfReference(0.015) solid #e6e6e6;
        border-radius: 10px;
        //padding: 24px 64px 24px 28px;
        padding-left: functions.responsivePercentageOfReference(0.25);
        padding-right: functions.responsivePercentageOfReference(0.5);
        padding-top: functions.responsivePercentageOfReference(0.15);
        padding-bottom: functions.responsivePercentageOfReference(0.15);
        width: 100%;
        //height: 78px;
        //height: functions.responsivePercentageOfReference(0.01);
        height: fit-content;
        transition: border 300ms;
        @include font.BodyFont();
        @include font.Small();

        &__multiline {
            //height: 120px;
            height: functions.responsivePercentageOfReference(1);
        }

        &__dashed {
            border-style: dashed;
        }

        &__solid {
            border-style: solid;
        }

        &:focus {
            border-color: var(--primary-color);
        }

        &:disabled {
            border-color: #e6e6e6;
        }

        &__success {
            border-color: #20a000;
            color: #20a000;
            @include font.SubheadingFont(false);
            @include font.Small();
        }

        &__error {
            border-color: #db0000;
        }
    }

    &__icon {
        position: absolute;
        //top: 23px;
        //right: 26px;

        top: functions.responsivePercentageOfReference(0.1);
        right: functions.responsivePercentageOfReference(0.1);
        i {
            font-size: functions.responsivePercentageOfReference(0.4);
            //font-size: 32px;
            color: #a4a4a4;
        }
    }

    &__errorText {
        margin-top: 12px;
        color: #db0000;
        @include font.BodyFont(false);
        @include font.Small();

        i {
            margin-right: 10px;
        }
    }

    &__suffixLabel {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: #a4a4a4;
        @include font.BodyFont(false);
        @include font.Small();
    }
}
