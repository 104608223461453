@use '/src/stylesheets/functions';

@import '/src/stylesheets/reset';
@import '/src/stylesheets/base';
@import '/src/stylesheets/font';
@import '/src/stylesheets/components';
@import 'react-responsive-carousel/lib/styles/carousel.min.css';
@import '/src/stylesheets/components/carousel';

.SVGFillContrast {
    & > svg {
        fill: var(--primary-contrast-color);
        height: 70%;
    }
}
