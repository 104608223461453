@use '/src/stylesheets/functions';
@use '/src/stylesheets/font';

.ProductLabels {
    display: flex;
    flex-direction: row;
    gap: functions.responsivePercentageOfReference(0.15);
    max-width: 100%;
    flex-wrap: wrap;

    &__stack {
        flex-direction: column;
        gap: functions.responsivePercentageOfReference(0.07);
        max-height: 100%;
        position: absolute;
    }

    &__horizontal {
        &__left {
            left: 0;
            align-items: start;
        }

        &__right {
            right: 0;
            align-items: end;
        }

        &__center {
            align-items: center;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__vertical {
        &__top {
            top: 0;
        }

        &__bottom {
            bottom: 0;
        }

        &__center {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__location__center {
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.ProductLabel {
    padding: functions.responsivePercentageOfReference(0.15);
    width: fit-content;
    @include font.BodyFont();
    @include font.SmallImportant();
    font-weight: bold;

    &__slim {
        @include font.ExtraSmallImportant();
        padding: functions.responsivePercentageOfReference(0.07);
        height: fit-content;
    }
}
