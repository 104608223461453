@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$modalBoxShadow: rgba(0, 0, 0, 0.5) 0px common.spacing('large') common.spacing('large') (common.spacing('small') * -1),
    rgba(0, 0, 0, 0.04) 0px common.spacing('medium') common.spacing('medium') (common.spacing('small') * -1);

$modalMarginMobile: common.$topMenuHeightMobile common.spacing('small') 0 common.spacing('small');
$modalMargin: common.$topMenuHeight common.spacing('large') * 2 0 common.spacing('large') * 2;
$modalMarginMobileCompact: common.spacing('small');
$modalMarginCompact: common.spacing('large') * 2;

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    @include mixins.z-index('modal');
    background: colours.$overlayColor;

    &__wrapper {
        @extend %flexCenter;
        position: relative;
        width: 100%;
        margin: auto;
    }

    &__wrapperCompact {
        @extend %flexCenter;
        position: relative;
        width: functions.responsivePercentageOfReference(7.5);
        max-width: functions.responsivePercentageOfReference(7.5);
        margin: auto;
    }

    &__body {
        top: 0;
        border-radius: common.$borderRadius;
        // border-radius: common.$borderRadius common.$borderRadius 0 0;
        background: colours.$white;
        overflow: hidden;
        box-shadow: $modalBoxShadow;
        max-width: common.$modalMaximumWidthTabletDesktop;
        width: 100%;
        -webkit-background-clip: padding-box;
    }

    &.expanded {
        background: #404040;

        & > * > .Modal__body {
            box-shadow: none;
            background: none;
            border-radius: 0;
        }

        & > .Modal__wrapper {
            margin: 5rem 1rem 0 1rem;

            @media screen and (min-width: 768px) {
                margin: 9.5rem 1.5rem 0 1.5rem;
            }
        }
    }
}
