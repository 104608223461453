@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.rdp {
    --rdp-cell-width: 40px;
}

.rdp-months {
    justify-content: center;
}

.rdp-nav_button {
    & > svg {
        width: functions.responsivePercentageOfReference(0.20) !important;
        height: functions.responsivePercentageOfReference(0.20) !important;
    }
}

.rdp-caption_label {
    @include font.CloudshelfFont();
}

.rdp-head_cell {
    @include font.CloudshelfFont();
    font-size: functions.responsivePercentageOfReference(0.20) !important;
}

.rdp-day {
    font-size: functions.responsivePercentageOfReference(0.20) !important;
    @include font.CloudshelfFont();
}

.rdp-table {
    max-width: 100% !important;
}

.rdp-cell {
    width: var(--rdp-cell-width) !important;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
}

.rdp-weeknumber,
.rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-width) !important;
    max-width: var(--rdp-cell-width) !important;
    height: var(--rdp-cell-size);
    margin: functions.responsivePercentageOfReference(0.05) !important;
    border: 3px solid black !important;
    border-radius: functions.responsivePercentageOfReference(0.25) !important
}


.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    color: white;
    opacity: 1;
    background-color: var(--rdp-accent-color);
}

//.rdp {
//    --rdp-cell-size: functions.responsivePercentageOfReference(0.10) !important;
//    --rdp-caption-font-size: functions.responsivePercentageOfReference(0.06) !important;
//}

//.BTACalendar {
//    background: none !important;
//    box-shadow: none !important;
//    width: 100% !important;
//    max-width: none !important;
//    padding-top: 0 !important;
//
//    font-size: functions.responsivePercentageOfReference(font.$smallFontSize) !important;
//    line-height: functions.responsivePercentageOfReference(font.$smallLineHeightSize) !important;
//}
//
//.Calendar__day {
//    font-size: functions.responsivePercentageOfReference(font.$smallFontSize) !important;
//    line-height: functions.responsivePercentageOfReference(font.$smallLineHeightSize) !important;
//    font-weight: bold;
//    border: 4px solid black;
//    padding: 10px;
//    border-radius: 70px !important;
//}
//
//.Calendar__weekRow {
//    //gap: functions.responsivePercentageOfReference(0.15);
//}
//
//.BTACalendarSelectedDay {
//}

.BtaErrorBlock {
    padding: functions.responsivePercentageOfReference(0.15) !important;
    background-color: rgba(208, 71, 70, 1);
    border-color: rgba(	201, 52, 51, 1);
    color: white;
}


.NoTimeslotWarningBox {
    padding: functions.responsivePercentageOfReference(0.15) !important;
    background-color: rgba(252, 248, 227, 1);
    border-color: rgba(177, 161, 129, 1);
}

.ResetDateButton {
    & > svg {
        width: functions.responsivePercentageOfReference(0.25) !important;
        height: functions.responsivePercentageOfReference(0.25) !important;
    }
}

.DatePadding {
    margin-top: calc(var(--responsive-reference-point) * 0.1);
    padding-top: calc(var(--responsive-reference-point) * 0.1);
}