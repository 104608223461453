@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

.PurchaseSuccessView {
    width: 100vw;
    height: mobile.vhCalc(100);
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__body {
        width: 100%;
        height: 100%;
        padding: functions.responsivePercentageOfReference(2);
        display: flex;
        flex-direction: column;
        gap: functions.responsivePercentageOfReference(0.1);
        justify-content: center;
        align-items: center;

        @include responsive.landscape() {
            padding: functions.responsivePercentageOfReference(1);
            gap: functions.responsivePercentageOfReference(0.05);
        }

        &__text {
            flex: 1;
        }

        &__green {
            color: var(--purchase-text-color) !important;
            text-align: center;
        }
    }

    &__tick {
        width: 45%;
        //height: 45%;
        //keep svg inside the div
        display: flex;
        justify-content: center;
        align-items: center;

        //padding: functions.responsivePercentageOfReference(2);

        @include responsive.landscape() {
            width: 25%;
            height: 25%;
            //padding: functions.responsivePercentageOfReference(1);
        }
    }

    &__tempBranding {
        flex: 1;
        display: flex;
        justify-content: end;
        align-items: center;
        flex-direction: column;
        gap: functions.responsivePercentageOfReference(0.1);

        &__logos {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: functions.responsivePercentageOfReference(0.25);
            flex-direction: row;

            & > img {
                width: 35%;
                height: auto;

                @include responsive.landscape() {
                    width: 15%;
                    height: auto;
                }
            }

            & > :first-child {
                width: 23%;
                height: auto;

                @include responsive.landscape() {
                    width: 10%;
                    height: auto;
                }
            }


            // first image in div

        }
    }

    &__finished {
        flex: 1;
        display: flex;
        justify-content: end;
        flex-direction: column;

        &__button {
            background-color: #000000;
        }
    }
}
