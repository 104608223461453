@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.PanelHeader {
    position: relative;
    width: 100%;
    @include common.space(
        common.spacing('medium') common.spacing('small'),
        common.spacing('large') common.spacing('medium')
    );

    &--withBorder {
        border-bottom: 1px solid #e8e8e8;
    }

    &__backButton {
        position: absolute;
        height: 100%;
        width: min-content;
        left: 0;
        top: 0;
        padding: 0;

        .ButtonIcon__icon {
            height: functions.responsivePercentageOfReference(0.65);
            width: functions.responsivePercentageOfReference(0.65);
        }

        > span > svg {
            stroke: black;
        }
    }

    &__closeButton {
        position: absolute;
        height: 100%;
        width: min-content;
        right: 0;
        top: 0;
        padding: 0;

        .ButtonIcon__icon {
            height: functions.responsivePercentageOfReference(0.65);
            width: functions.responsivePercentageOfReference(0.65);
        }
        > span > svg {
            fill: black;
            height: 100%;
        }
    }

    &__body {
        @extend %flexCenter;
        position: relative;
        width: 100%;
        height: 100%;

        &__leftAlign {
            justify-content: flex-start !important;
        }
    }

    &__title {
        @include mixins.line-clamp(2);
    }
}
