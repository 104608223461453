.TouchRipple {
  position: absolute;
  top: -15vw;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.pulse-circle {
  position: relative;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);
  color: white;
  animation: touch-ripple-pulse 1.5s infinite;
}

@keyframes touch-ripple-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}