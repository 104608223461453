@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

.NewPurchaseView {
    width: 100%;
    height: 100%;
    background-color: #f6f6f7;
    display: flex;
    flex-direction: column;

    &__success {
        color: var(--purchase-text-color) !important;
    }

    &__failed {
        color: #ef2a2a !important;
    }

    &__body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: functions.responsivePercentageOfReference(0.25);
        gap: functions.responsivePercentageOfReference(0.25);
    }

    &__acquisitionCost {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: functions.responsivePercentageOfReference(0.025);

        &__title {
            @include font.SubheadingFont();
            @include font.Small();
        }

        &__price {
            color: black !important;
            @include font.HeadingFont();
            @include font.Small();
            font-weight: bolder !important;
        }
    }

    &__totalPrice {
        color: black !important;
        @include font.HeadingFont();
        @include font.Medium();
    }

    &__options {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: functions.responsivePercentageOfReference(0.5);
        width: 100%;
        padding: 0 functions.responsivePercentageOfReference(1);
    }

    &__stackedOption {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: functions.responsivePercentageOfReference(0.25);

        &__icon {
            background-color: white;
            padding: functions.responsivePercentageOfReference(0.4);
            border-radius: var(--border-radius);
            width: functions.responsivePercentageOfReference(2);
            height: functions.responsivePercentageOfReference(2);
            box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.1);
            transition: all 0.5s ease-in-out;

            & svg {
                width: 100%;
                height: 100%;
            }

            &__scanned {
                width: functions.responsivePercentageOfReference(4.5);
                height: functions.responsivePercentageOfReference(4.5);
                background: #dcdde150;
            }
        }

        &__button {
            width: functions.responsivePercentageOfReference(2);
        }

        &__text {
            color: #434343 !important;
        }
    }

    &__sideStackedOption {
        flex: 1;
        display: flex;
        flex-direction: row;
        // align-items: center;
        justify-content: center;
        gap: functions.responsivePercentageOfReference(0.5);
    }

    &__receiptArea {
        border-radius: var(--border-radius);
        border: functions.responsivePercentageOfReference(0.03) solid #c6c6c6;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: functions.responsivePercentageOfReference(0.125);
        // width: 100%;
        flex: 1;
        height: min-content;
        align-self: center;

        &__inputLabel {
            color: #959595 !important;
        }

        &__input {
            width: 100%;
            padding: functions.responsivePercentageOfReference(0.1) functions.responsivePercentageOfReference(0.1);
            margin: functions.responsivePercentageOfReference(0.035) 0;
            box-sizing: border-box;
            border-radius: var(--border-radius);
            border: functions.responsivePercentageOfReference(0.025) solid #c6c6c6;
            font-size: functions.responsivePercentageOfReference(0.25);
            font-weight: normal;
        }

        &__inner {
            padding: functions.responsivePercentageOfReference(0.45);
            display: flex;
            flex-direction: column;
            gap: functions.responsivePercentageOfReference(0.25);
            width: 100%;
        }

        &__successText {
            color: var(--purchase-text-color) !important;
        }

        &__submitButton {
            width: min-content;
            background: #e0e0e0 !important;
            outline: 1px #e0e0e0 !important;
            color: #000 !important;

            &__enabled {
                background: #000 !important;
                outline: 1px #000 !important;
                color: #fff !important;
            }
        }
    }

    &__illustrationContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: functions.responsivePercentageOfReference(0.1);

        &__illustration {
            margin-top: functions.responsivePercentageOfReference(0.5);
            height: functions.responsivePercentageOfReference(3);

            & > svg {
                height: 100%;
            }
        }
    }

    &__paymentIcons {
        max-width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: functions.responsivePercentageOfReference(0.1);
        margin-bottom: functions.responsivePercentageOfReference(0.1);
        flex-wrap: wrap;
        gap: functions.responsivePercentageOfReference(0.1);
    }
}
