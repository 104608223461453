@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

.InteractiveApp {
  &__container {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;


    @include responsive.landscape() {
      flex-direction: row;
    }

    &__pageContent {
      display: flex;
      flex: 1;
      position: relative;
      overflow: clip;
    }
  }
}