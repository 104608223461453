@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.Quantity {
    @extend %flexCenter;
    flex-flow: row;

    &__spacebetween {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        align-items: center !important;
        width: 100%;
    }

    &__wrapper {
        &__controls {
            display: flex !important;
            flex-direction: row !important;
            justify-content: space-between !important;
            align-items: center !important;
        }

        &__remove {
        }
    }

    &__button {
        //padding: 0;
        background-color: var(--primary-color);
        color: var(--primary-contrast-color);
    }

    &__count {
        display: flex;
        // @include common.space(0 common.spacing('xSmall'), 0 common.spacing('small'));
        margin: 0 functions.responsivePercentageOfReference(0.1);
        text-align: center;
        &__smaller {
            @include font.BodyFont();
            @include font.Small();
        }
    }

    &__removeButton {
        background: transparent;
        border: none;
        padding: 0;
        // @include common.space(common.spacing('medium'), common.spacing('large'), 'margin-left');

        &__icon {
            width: 100%;
            height: 100%;
            & > * {
                height: functions.responsivePercentageOfReference(0.45);
            }
        }
        &:active {
            font-weight: var(--body-font-weight) !important;
        }
    }
}
