@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/common';

.BasketPane {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: common.$pdpPadding;
    padding-top: functions.responsivePercentageOfReference(0.4);

    @include responsive.landscape() {
        flex-direction: column;
    }

    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: functions.responsivePercentageOfReference(0.25);
        margin-bottom: functions.responsivePercentageOfReference(0.25);
    }

    &__header {
        width: 100%;

        @include responsive.landscape() {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: functions.responsivePercentageOfReference(0.25);
        height: 100%;
        color: #000000;

        @include responsive.landscape() {
            padding: functions.responsivePercentageOfReference(0.4);
        }

        & > svg {
            width: 100%;
            flex: 1;

            @include responsive.landscape() {
                flex: unset;
            }
        }
        &__text {
            color: #b7b7b7 !important;
            flex: 1;

            @include responsive.landscape() {
                flex: unset;
            }
        }
    }

    &__content {
        height: 100%;
        position: sticky !important;
        width: 100%;
        // padding: functions.responsivePercentageOfReference(0.2);
        overflow: unset;
        overflow-y: auto;
        min-width: 100%;

        @include responsive.landscape() {
            // padding: 0 functions.responsivePercentageOfReference(0.2) functions.responsivePercentageOfReference(0.2);
            overflow: unset;
            overflow-x: auto;
        }

        &__items {
            //items

            flex: 1;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: functions.responsivePercentageOfReference(0.15);
            position: absolute !important;
            min-width: 100%;

            @include responsive.landscape() {
                flex-direction: column-reverse;
                gap: functions.responsivePercentageOfReference(0.2);
            }
        }
    }

    &__footer {
        //footer
        display: flex;
        flex-direction: column;
        justify-content: start;

        gap: functions.responsivePercentageOfReference(0.15);

        @include responsive.landscape() {
            flex-direction: column;
            justify-content: center;

            gap: functions.responsivePercentageOfReference(0.2);
            border-left: unset;
        }

        &__total {
            text-align: center;
        }

        &__purchase {
            width: 100%;
            background-color: var(--purchase-text-color);
            border-color: var(--purchase-text-color);
            outline-color: var(--purchase-text-color);
            outline: none;
            &__finish {
                background-color: var(--purchase-text-color);
                border-color: var(--purchase-text-color);
                outline-color: var(--purchase-text-color);
                outline: none;
            }
        }
    }
}
