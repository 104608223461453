@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

$cartRelativeSize: common.$contentSizeRelative;

hr {
    height: 1px;
    background-color: #e8e8e8;
    border: none;
    @include common.space(common.spacing('medium'), common.spacing('medium'), 'margin-top');
    @include common.space(common.spacing('medium'), common.spacing('medium'), 'margin-bottom');
}

:root {
    --product-view-content-margin: #{common.spacing('medium')};
    --product-view-content-margin-negative: -#{common.spacing('medium')};
    --product-view-content-margin-neative-double: -#{common.spacing('xxLarge')};

    @include responsive.tabletDesktop {
        --product-view-content-margin: #{common.spacing('large')};
        --product-view-content-margin-negative: -#{common.spacing('large')};
        --product-view-content-margin-neative-double: -#{common.spacing('xxxxLarge')};
    }
}

.productDisplayView {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    color: black;
    overflow: scroll;

    &__Title {
        background: rgba(255, 255, 255, 0.55);
        padding: functions.responsivePercentageOfReference(0.075);
    }
    &__sections {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include responsive.landscape {
            flex-direction: row;
            height: 100%;
        }

        &__controlBar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: common.$pdpPadding;
            height: functions.responsivePercentageOfReference(common.$contentSizeRelative);

            &__section {
                display: flex;
                flex-direction: row;
                gap: functions.responsivePercentageOfReference(0.15);
                transition: all 0.5s ease-in-out;
            }
        }

        &__sideBySide {
            display: flex;
            flex-direction: row;
            flex: 1;
            overflow: auto;

            &__left {
                width: 75%;
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                transition: all 0.5s ease-in-out;

                @include responsive.landscape {
                    flex-direction: row;
                    width: 100%;
                    height: 100%;
                }

                &__full {
                    width: 100%;
                    height: 100%;
                }
            }

            &__right {
                width: 25%;
                height: 100%;
                transition: all 0.5s ease-in-out;

                @include responsive.landscape {
                    width: 100%;
                    height: 100%;
                }

                &__hidden {
                    width: 0;
                }
            }
        }

        &__one {
            width: 100%;
            height: 62%;
            min-height: 62%;
            transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
            background: #f6f6f7;
            position: relative;

            &__content {
                width: 100%;
                height: 100%;

                &__child {
                    &:only-child {
                        height: 100%;
                    }
                }
            }

            @include responsive.landscape {
                height: 100%;
                width: 58%;
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &__fullscreen {
                height: 100%;
                width: 100%;
            }
        }

        &__two {
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;
            background: #f6f6f7;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: functions.responsivePercentageOfReference(0.15);

            &__container {
                width: 100%;
                height: 100%;
                padding: common.$pdpPadding;
                display: flex;
                flex-direction: column;
                gap: functions.responsivePercentageOfReference(0.17);
            }

            @include responsive.landscape {
                width: 42%;
                overflow: scroll;
                height: 100%;
            }

            &__hidden {
                margin-bottom: var(--product-view-content-margin-neative-double);
                height: 0;

                @include responsive.landscape {
                    margin-bottom: 0;
                    padding: 0;
                    width: 0;
                    opacity: 0;
                    height: 100%;
                }
            }
        }

        &__top {
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.5s ease-in-out;
            // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0));
            width: 100%;
            height: fit-content;
            opacity: 1;

            &__hidden {
                opacity: 0;
            }

            &__split {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__content {
                    display: flex;
                    flex-direction: column;
                    padding: common.$pdpPadding;
                    gap: functions.responsivePercentageOfReference(0.15);
                    align-items: end;
                }
            }
        }

        &__suggestedItems {
            width: 100%;
            height: 100%;
            max-height: 100%;

            background: #dcdde1;
            padding: common.$pdpPadding;

            @include responsive.landscape {
                width: 100%;
                height: 28%;
            }

            &__title {
                text-align: center;
                @include responsive.landscape {
                    text-align: left;
                }
            }

            &__list {
                max-height: 100%;
                display: flex;
                flex-direction: column;
                padding-top: common.$pdpPadding;
                padding-bottom: common.$pdpPadding;
                gap: functions.responsivePercentageOfReference(0.15);
                overflow: auto;
                align-items: center;

                @include responsive.landscape {
                    flex-direction: row;
                    padding-bottom: 0px;
                }
            }
        }

        &__images {
            height: 100%;
            width: 100%;
            transition: width 0.5s ease-in-out, height 0.5s ease-in-out;

            @include responsive.landscape {
                height: 72%;
                width: 100%;
                //     flex-direction: row;
                //     height: 100%;
                //     width: 57%;
                //     min-height: 100%;
                //     min-width: 57%;
            }

            &__fullscreen {
                height: 100%;
                width: 100%;
            }

            &__empty {
                height: mobile.vhCalc(10);
                background-color: black;

                &__text {
                    text-align: center;
                    color: white !important;
                    line-height: mobile.vhCalc(10) !important;

                    @include responsive.landscape {
                        line-height: mobile.vhCalc(100) !important;
                    }
                }
            }
        }
    }

    &__toasterContainer {
        > div {
            > div {
                max-width: 100%;
            }
        }
    }

    &__toast {
        display: flex;
        align-items: center;
        color: colours.$white;
        width: 100%;

        > * {
            color: colours.$white;
        }

        > i {
            margin-right: 1rem;
        }
    }

    &__vertical__noScroll {
        overflow: hidden;

        @include responsive.landscape {
            overflow: auto;
        }
    }

    @include responsive.landscape {
        flex-direction: row;
    }

    &__zIndex {
        @include mixins.z-index('productView');
    }

    &__cartOptions {
        position: absolute;
        bottom: 0;
        right: 0;
        //height: 120px;
        height: var(--responsive-reference-point);
        width: 100%;
        background: rgba(255, 255, 255, 0.95);
        transition: all 0.5s ease-in-out;

        @include responsive.landscape {
            width: 42%;
        }

        &__hidden {
            margin-bottom: calc(
                (-1 * var(--responsive-reference-point)) + var(--product-view-content-margin-neative-double)
            );
        }

        &__spacer {
            min-height: calc(var(--responsive-reference-point) + var(--product-view-content-margin));
        }
    }
}
