@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.DiscreteFilterInput {
    overflow: hidden;
    -moz-transition: max-height 0.15s ease-out;
    -ms-transition: max-height 0.15s ease-out;
    -o-transition: max-height 0.15s ease-out;
    -webkit-transition: max-height 0.15s ease-out;
    transition: max-height 0.15s ease-out;
    padding: functions.responsivePercentageOfReference(0.025);

    &__expanded {
        -moz-transition: max-height 0.15s ease-in;
        -ms-transition: max-height 0.15s ease-in;
        -o-transition: max-height 0.15s ease-in;
        -webkit-transition: max-height 0.15s ease-in;
        transition: max-height 0.15s ease-in;
    }

    &__chevron {
        padding-left: 10px;
        padding-bottom: 3px;
    }

    &__showMore {
        position: relative;
        bottom: 0;
        background: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        border: 0;
        outline: inherit;
        height: fit-content;
        color: #a4a4a4;
        width: 100%;
        -webkit-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.05);
        box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.05);
        @include common.space(common.spacing('xSmall'), common.spacing('xSmall'), padding);
    }

    &__optionsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: functions.responsivePercentageOfReference(0.075);
    }

    &__optionsListItem {
        // @include common.space(common.spacing('xSmall'), common.spacing('xSmall'), margin-right);
        // @include common.space(common.spacing('xSmall'), common.spacing('xSmall'), margin-bottom);
    }

    &__option {
        margin-right: 0;
    }
}
