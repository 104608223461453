@use '/src/stylesheets/common';
@use '/src/stylesheets/functions';

.carousel-root {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
    height: 100%;
    max-height: 100%;
}

.slide {
    height: 100%;
    max-height: 100%;
}

.cloudshelf-carousel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__background__image {
        filter: blur(8px);
        // transform: scale(1.1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &__expanded {
            object-fit: contain;
        }
    }

    &__container {
        flex: 1;
        overflow: auto;
        position: relative;
        // background: linear-gradient(180deg, #ececee, #f5f5f7);
        background: linear-gradient(340deg, #dcdde1, #f5f5f7);
    }

    &__controls {
        position: absolute;
        left: 0;
        bottom: 0;
        height: fit-content;
        width: 100%;
    }

    &__thumbnails {
        padding: functions.responsivePercentageOfReference(0.15) common.$pdpPadding;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        gap: functions.responsivePercentageOfReference(0.15);
        overflow: scroll;
        align-items: center;
        transition: all 0.1s;

        &__noPadding {
            padding: 0;
        }

        &__hidden {
            opacity: 0;
            height: 0;
            padding: 0;
        }

        &__thumb {
            flex-shrink: 0;
            width: functions.responsivePercentageOfReference(0.75);
            height: functions.responsivePercentageOfReference(0.75);
            min-height: functions.responsivePercentageOfReference(0.75);
            min-width: functions.responsivePercentageOfReference(0.75);
            max-width: functions.responsivePercentageOfReference(0.75);
            max-height: functions.responsivePercentageOfReference(0.75);
            border-radius: var(--tiles-radius);

            & > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: var(--tiles-radius);
            }

            &__active {
                outline: functions.responsivePercentageOfReference(0.015) solid var(--primary-color);
                transition: all 0.1s;
            }
        }
    }
}
