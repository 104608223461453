@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.PairingApp {
    &_white {
        color: white !important;
    }

    &_deviceCode {
        @include font.CloudshelfFontBold();
        text-align: center;
        overflow: visible;

        font-size: 90px;
        @include responsive.mobileOnly {
            font-size: 80px;
        }
        color: white;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &_title {
        display: flex;
        flex-direction: column;
        flex: 9;
        align-items: center;
        justify-content: center;
    }
}