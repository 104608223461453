@use '/src/stylesheets/functions';

.Accordion {
    &__button {
        // background-color: transparent;
        background-color: transparent;
        cursor: pointer;
        // padding: 10px;
        padding-left: 0 !important;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: all 0.4s ease-in-out;
    }

    &__panel {
        padding: 0;
        // background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        margin-top: functions.responsivePercentageOfReference(0.15);

        &__open {
            // margin-top: functions.responsivePercentageOfReference(0.15);
            max-height: min-content;
        }

        // &__partialClose {
        //     min-height: calc(6 * functions.responsivePercentageOfReference(0.25));
        // }
    }
}
