@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mixins';

.baseServiceScreen {
    background: linear-gradient(180deg, rgb(255, 57, 104) 0%, rgb(184, 1, 116) 100%);
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    &__background {
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > svg {
            width: 50%;
            height: auto;

            @include responsive.landscape() {
                height: 50%;
                width: auto;
            }

        }
    }

    &__children {
        display: flex;
        flex-direction: column;
        flex: 9;
        align-self: center;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin: 0 5vw;
    }

    &__logo {
        height: 5vh;
        margin: auto auto 5vh;
    }
}
