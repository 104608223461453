@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';
@use 'sass:math';

$borderThickness: 0.125rem;
$borderCornerSize: math.div(common.$qrCodeSize, 10);

.PurchaseQrCode {
    position: relative;

    // &__tick {
    //     width: 100%;
    //     height: 100%;
    //     background: #dcdde150;
    // }

    &__confetti {
        width: functions.responsivePercentageOfReference(4);
        height: functions.responsivePercentageOfReference(4);
        position: relative;
        top: functions.responsivePercentageOfReference(-1);
        left: functions.responsivePercentageOfReference(-1);
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex;

        & > svg {
            width: 100%;
            height: 100%;
        }

        &:before {
            bottom: (-1 * $borderThickness);
            left: (-1 * $borderThickness);
            border-bottom: $borderThickness solid colours.$black;
            border-left: $borderThickness solid colours.$black;
        }

        &:after {
            bottom: (-1 * $borderThickness);
            right: (-1 * $borderThickness);
            border-bottom: $borderThickness solid colours.$black;
            border-right: $borderThickness solid colours.$black;
        }
    }

    &__countdown {
        display: flex;
        flex-direction: column;
        color: #ffffff;
        text-align: center;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

/* Lottie seems to ignore display properties unless you do this... */
.lf-player-container {
    display: flex;
    height: 100%;
}
