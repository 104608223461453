@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.FilterWrapper {
    margin-bottom: functions.responsivePercentageOfReference(0.08);

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__name {
            @include font.SubheadingFont();
            @include font.Small();
            margin-bottom: functions.responsivePercentageOfReference(0.08);
            // @include common.space(common.spacing('xSmall'), common.spacing('small'), margin-bottom);
        }

        &__chevron {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            height: fit-content;
        }
    }

    &__body {
        overflow: hidden;
        max-height: 0;
        -moz-transition: max-height 0.15s ease-out;
        -ms-transition: max-height 0.15s ease-out;
        -o-transition: max-height 0.15s ease-out;
        -webkit-transition: max-height 0.15s ease-out;
        transition: max-height 0.15s ease-out;
        padding-top: functions.responsivePercentageOfReference(0.02);

        &__expanded {
            max-height: max-content !important;
            -moz-transition: max-height 0.15s ease-in;
            -ms-transition: max-height 0.15s ease-in;
            -o-transition: max-height 0.15s ease-in;
            -webkit-transition: max-height 0.15s ease-in;
            transition: max-height 0.15s ease-in;
        }
    }
}
