@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$imageSizeMobile: 6.5rem;
$imageSizeTabletDesktop: 13rem;
$menuRelativeSize: common.$contentSizeRelative;

.BasketPaneItem {
    display: flex;
    flex-direction: column;
    //width: 15vw;
    width: functions.responsivePercentageOfReference(1.5);
    gap: functions.responsivePercentageOfReference(0.15);
    height: 80%;
    @include responsive.landscape() {
        width: 100%;
        flex-direction: row;
        gap: functions.responsivePercentageOfReference(0.1);
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &__image {
        flex: 1;
        display: flex;
        width: 100%;
        //height:
        max-height: 8vh;
        //aspect-ratio: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
        filter: drop-shadow(0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25));
        background-color: white;
        border-radius: var(--tiles-radius);
        // margin-right: functions.responsivePercentageOfReference(0.15);

        @include responsive.landscape() {
            max-width: 50%;
            max-height: unset;
            height: unset;
            aspect-ratio: 1;
        }

        &__quantity {
            background-color: #646464;
            border: #646464 1px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            height: functions.responsivePercentageOfReference(0.4) !important;
            aspect-ratio: 1;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            left: 5px;
            @include font.BodyFont();
            font-size: functions.responsivePercentageOfReference(0.2);
            line-height: functions.responsivePercentageOfReference(0.2);
            color: white;
            z-index: 0;
        }

        &__discount {
            background-color: var(--purchase-text-color);
            border-radius: functions.responsivePercentageOfReference(0.05);
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 0;

            &__text {
                color: white !important;
                padding: functions.responsivePercentageOfReference(0.025)
                    functions.responsivePercentageOfReference(0.07);
                @include font.BodyFont();
                @include font.ExtraSmallImportant();
            }
        }

        &__img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            // height: 100%;
            //width: $imageSizeMobile;
            //height: $imageSizeMobile;
            border-radius: var(--tiles-radius);
            //@include responsive.tabletDesktop() {
            //    width: $imageSizeTabletDesktop;
            //    height: $imageSizeTabletDesktop;
            //}

            @include responsive.landscape() {
                height: 100%;
            }
        }
    }

    &__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 6vh;

        @include responsive.landscape() {
            max-height: unset;
        }

        &__details {
            flex: 1;
            max-height: 4vh;

            @include responsive.landscape() {
                max-height: 100%;
            }

            &__text {
                overflow: hidden;
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-align: center;

                @include responsive.landscape() {
                    -webkit-line-clamp: 3;
                    text-align: unset;
                }
            }

            &__price {
                text-align: center;
                @include responsive.landscape() {
                    text-align: unset;
                }
            }

            @include responsive.landscape() {
                height: 100%;
            }
        }

        &__footer {
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include responsive.landscape() {
                flex-direction: row;
                justify-content: left;
            }

            &__removeButton {
                all: unset;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                background: transparent;
                pointer-events: all;
                font-size: functions.responsivePercentageOfReference(0.2);
                @include font.BodyFont();
                color: #ff0000;
            }
        }
    }
}
