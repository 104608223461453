@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.CheckoutTotalOverlay {
    @extend %flexCenter;
    @include mixins.z-index(SlidingModal);
    padding: functions.responsivePercentageOfReference(0.25);
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #e8e8e8;

    &__checkoutButtons {
        display: flex;
        flex-direction: row;
        gap: functions.responsivePercentageOfReference(0.25);

        &__button {
            background-color: transparent;
            border-color: var(--purchase-text-color);
            outline-color: var(--purchase-text-color);
            color: black;

            &__prefered {
                background-color: var(--purchase-text-color);
                border-color: var(--purchase-text-color);
                outline-color: var(--purchase-text-color);
            }
        }
    }

    &__totalContainer {
        width: 100%;
        //max-width: common.$modalMaximumWidthTabletDesktop;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: functions.responsivePercentageOfReference(0.25);
    }

    &__summary {
        width: 100%;
        //max-width: common.$modalMaximumWidthTabletDesktop;
        @include common.space(common.spacing('medium'), common.spacing('xLarge'), 'margin-bottom');
    }

    &__totalPrice {
        flex-shrink: 0;
    }

    &__subTotalPrice {
        text-decoration: line-through;
    }

    &__discount {
        & > * {
            color: var(--purchase-text-color);
        }
    }

    &__totalLabel {
        & > * {
            color: #a4a4a4;
        }
    }
}
