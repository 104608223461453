.ProductDescription {
    pointer-events: none;
    text-decoration: none;
    color: inherit;

    &__AllowDefaultStyles {
        & * {
            all: revert;
            text-decoration: none;
            pointer-events: none;
            color: inherit;
        }

        & p {
            margin-top: 0 !important;
        }
    }
}
